import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  margin: 30px auto 0 !important;
  transform: translateX(
    calc(50vw - 50% - ${props => props.theme.spacing.siteXPadding})
  );
  cursor: pointer;
`

const LoadMoreButton = ({ title }) => <Button className="btn">{title}</Button>

export default LoadMoreButton
