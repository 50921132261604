import dayjs from 'dayjs'
import { graphql, Link } from 'gatsby'
import React, { useState } from 'react'
// import AddToCalendar from 'react-add-to-calendar'
import styled from 'styled-components'
import Container from '../components/Container'
import InPageNav from '../components/InPageNav'
import Layout from '../components/Layout'
import LoadMoreButton from '../components/LoadMoreButton'
import MediaObject from '../components/MediaObject'
import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import SEO from '../components/SEO'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  /* background-color: ${props => props.theme.colors.nearlyWhite}; */
`

const MonthGroup = styled.div`
  h1 {
    margin-top: 30px;
  }
`

const EventsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
`

const Calendar = ({ data }) => {
  const contentfulEvents = data.allContentfulEvent.edges

  // const futureEvents = contentfulEvents.filter(
  //   ({ node: event }) => dayjs(event.startTime) > dayjs()
  // )

  const sortEventsByMonth = events => {
    const sortedEvents = {}

    for (const event of events) {
      const date = dayjs(event.node.startTime)
      const key = `${date.month()}_${date.year()}`
      if (!sortedEvents[key]) {
        sortedEvents[key] = []
      }
      sortedEvents[key].push(event)
    }

    const eventsByMonth = []
    for (const events of Object.values(sortedEvents)) {
      const eventObj = {
        dateHeading: dayjs(events[0].node.startTime).format('MMMM YYYY'),
        events,
      }
      eventsByMonth.push(eventObj)
    }
    return eventsByMonth
  }

  const eventsByMonth = sortEventsByMonth(contentfulEvents)
  const totalEventsByMonth = eventsByMonth.length
  const [eventMonthsLoaded, setEventMonthsLoaded] = useState(3)
  const eventsByMonthGroup = eventsByMonth.slice(0, eventMonthsLoaded)

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Calendar"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/calendar/" activeClassName="active-page-link">
            Events
          </Link>
          <Link to="/calendar/subscribe/" activeClassName="active-page-link">
            Subscribe
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            {eventsByMonthGroup.map((monthOfEvents, index) => {
              return (
                <MonthGroup key={`${monthOfEvents.events[0].id}${index}`}>
                  <h1 className="section-title">{monthOfEvents.dateHeading}</h1>
                  <EventsContainer>
                    {monthOfEvents.events.map(({ node: event }) => {
                      return (
                        <MediaObject
                          key={event.id}
                          isCalendar="true"
                          slug={`calendar/${event.slug}/`}
                          title={event.title}
                          body={event.body.childMarkdownRemark.excerpt}
                          // category={event.eventCategory || null}
                          startDate={event.startTime}
                          startTime={event.startTime}
                          endTime={event.endTime}
                        />
                        // <AddToCalendar
                        //   event={{
                        //     title: event.title,
                        //     description: event.body.childMarkdownRemark.excerpt,
                        //     location: event.location || '',
                        //     startTime: event.startTime,
                        //     endTime: event.endTime,
                        //   }}
                        //   buttonLabel="Add to Calendar"
                        //   displayItemIcons={false}
                        //   listItems={[
                        //     { apple: 'Apple Calendar' },
                        //     { google: 'Google Calendar' },
                        //     { outlook: 'Other Calendar' },
                        //   ]}
                        // />
                      )
                    })}
                  </EventsContainer>
                </MonthGroup>
              )
            })}
            {totalEventsByMonth > eventMonthsLoaded && (
              <div onClick={() => setEventMonthsLoaded(eventMonthsLoaded + 3)}>
                <LoadMoreButton title="Load More Events" />
              </div>
            )}
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query($timeNow: Date) {
    allContentfulEvent(
      filter: { startTime: { gt: $timeNow } }
      sort: { fields: [startTime], order: [ASC] }
    ) {
      edges {
        node {
          title
          id
          slug
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 246)
            }
          }
          startTime
          endTime
          eventCategory {
            title
          }
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Calendar
